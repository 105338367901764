import axios from 'axios';
import axiosInstance from './axiosInstance';
import { AUTH_DOMAIN } from 'services/appDomain';

const AUTH_HOST = AUTH_DOMAIN;

// ========== SERVICE SETUP ==========
const login = (data: any) => {
  return axiosInstance
    .post(`${AUTH_HOST}/auth/login`, { ...data })
    .then((response: any) => response)
    .catch((error: any) => {
      return error?.response;
    });
};

const signUp = (data: any) => {
  return axiosInstance
    .post(`${AUTH_HOST}/auth/sign-up`, { ...data })
    .then((response: any) => response)
    .catch((error: any) => {
      return error?.response;
    });
};

const refreshToken = (data: any) => {
  return axios.post(`${AUTH_HOST}/auth/refresh-token`, { ...data });
};

const authService = {
  login,
  signUp,
  refreshToken,
};

export default authService;
