import { lazy } from 'react';
// project imports
import MainLayout from 'layouts/MainLayout';
import Loadable from 'layouts/Loadable';

const Home = Loadable(lazy(() => import('features/home')));
const Products = Loadable(lazy(() => import('features/products')));
const About = Loadable(lazy(() => import('features/about')));
const Profile = Loadable(lazy(() => import('features/profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: any = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/products',
      element: <Products />,
    },
    {
      path: '/profile',
      element: <Profile />,
    },
  ],
};

export default MainRoutes;
