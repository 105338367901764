import { lazy } from 'react';
import AuthLayout from 'layouts/AuthLayout';
import Loadable from 'layouts/Loadable';

const Login = Loadable(lazy(() => import('features/Login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/login',
      element: <Login />,
    },
  ],
};

export default AuthenticationRoutes;
