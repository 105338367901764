import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import authService from 'services/authService';

const useGuard = () => {
  const isAuth = authService.isAuthenticated();

  const navigate = useNavigate();
  const location = useLocation();

  const URLS = ['/login'];
  const [searchParams] = useSearchParams();
  const previousUrl = localStorage.getItem('previousUrl');
  const userInfo = localStorage.getItem('userInfo');
  const fromUrl = searchParams.get('from');
  const logoutUrl = searchParams.get('logout');

  useEffect(() => {
    // check url redirect from business setting
    if (location.search) {
      if (logoutUrl) {
        //check logout
        if (isAuth) {
          authService.clearSession();
          navigate('/login');
        } else {
          navigate('/login');
        }
        localStorage.setItem('previousUrl', fromUrl || '');
      } else {
        // check login to getToken
        if (isAuth) {
          const _fromUrl = localStorage.getItem('previousUrl');
          localStorage.removeItem('previousUrl');
          window.location.assign(`${_fromUrl ? _fromUrl : fromUrl}?userInfo=${userInfo}`);
        } else {
          const fromUrl = searchParams.get('from');
          localStorage.setItem('previousUrl', fromUrl || '');
          navigate('/login');
        }
      }
    }

    if (!isAuth) {
      if (!URLS.includes(location.pathname)) {
        navigate(`/login`);
      }
    } else {
      if (previousUrl) {
        const _fromUrl = localStorage.getItem('previousUrl');
        localStorage.removeItem('previousUrl');
        window.location.assign(`${_fromUrl ? _fromUrl : fromUrl}?userInfo=${userInfo}`);
      } else {
        // navigate('/');
        return;
      }
    }
  }, [location, isAuth, userInfo, fromUrl]);
};

export default useGuard;
