import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import useGuard from 'hooks/useGuard';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // call hook => check auth
  useGuard();
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
