import { AxiosInstance } from 'axios';
import axiosInstance from './axiosInstance';

class AuthService {
  api: AxiosInstance;

  constructor() {
    this.api = axiosInstance;
  }

  handleAuthentication = () => {
    const accessToken: any = this.getAccessToken();
    // if (!accessToken || !this.isValidToken(accessToken)) return;
    this.setSession('accessToken', accessToken);
  };

  getTenantId = () => {
    const _userInfo = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(JSON.stringify(_userInfo));
    const tenantId = userInfo?.tennantId || '';
    return tenantId;
  };

  loginWithAuth0 = async (username: string, roleUser: string) => {
    const accessToken = '1929312831903129321';
    this.setSession('accessToken', accessToken);
    const userStringify = JSON.stringify({ username, roleUser });
    this.setSession('user', userStringify);
    return {
      user: username,
      role: roleUser,
    };
  };

  loginWithToken = async () => {
    return {
      user: 'tonynguyen',
    };
  };

  setSession = (key: string, accessToken: string) => {
    localStorage.setItem(key, accessToken);
  };

  logOut = () => {
    localStorage.clear();
  };

  getUser = () => {
    const user = localStorage.getItem('user') || '';
    return user;
  };

  clearSession = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
