import { FC, useEffect } from 'react';
import ErrorBoundary from 'containers/ErrorBoundary';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import authService from 'services/authService';

const AuthLayout: FC = ({ children }: any) => {
  const isAuth = authService.isAuthenticated();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuth) navigate('/');
  }, []);
  return (
    <div>
      <main>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default AuthLayout;
