// material core
import { ThemeProvider } from '@mui/material/styles';

// context
import { useGlobalContext } from 'context/GlobalContext';

// containers
import Auth from 'containers/Auth';

// themes
import { THEMES } from 'configs';
import themes from 'themes';

// routes
import Routes from 'routes';
//test

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useState } from 'react';
Amplify.configure(awsconfig);

function App({ signOut, user }: any) {
  const { modeTheme, language } = useGlobalContext();
  const type = modeTheme === THEMES.LIGHT ? 0 : 1;
  const [localValue, setLocalValue] = useState('');
  return (
    <ThemeProvider theme={themes(type)}>
      {/* <Auth> */}
      <Routes />
      {/* </Auth> */}
    </ThemeProvider>
  );
}

export default App;
