import { IAuthActionTypes, IAuthActionCreator, IAuthState } from 'models/IAuthState';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

const initialState: IAuthState = {
  user: null,
  role: null,
};

// reducers
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login_success: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
      state.role = action.payload.role;
    },
    login_failure: (state) => {
      state.user = null;
    },
    logout: (state) => {
      state.user = null;
      state.role = null;
    },
    silent_login: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
      state.role = action.payload.role;
    },
  },
});

// actions
export const { login_success, login_failure, logout, silent_login } = authSlice.actions;

// selectors
// export const selectCount = (state: RootState) => state.counter.value;

// const {reducer: authReducer} = authSlice;

export default authSlice.reducer;
