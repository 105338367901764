import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './auth.service';
import { AUTH_DOMAIN } from 'services/appDomain';

const messageNotifyEnglish = {
  GeneralInternalServerError: 'Server error',
  GeneralInvalidParameters: 'Invalid parameters',
  TokenIsExpired: 'Token is expired',
  Unauthorized: 'Unauthorized',
  NewPasswordIsRequired: 'NewPassword is required',
  UsernameIsRequired: 'Username is required',
  UserAlreadyExisted: 'User already existed',
  EmailAlreadyExisted: 'Email already existed',
  InvalidPassword: 'Invalid password',
  GroupNotFound: 'Group not found',
  AliasAlreadyExisted: 'Alias already existed',
  BusinessNameIsRequired: 'BusinessName is required',
  BusinessPhoneIsRequired: 'BusinessPhone is required',
  IndustryIsRequired: 'Industry is required',
  TenantIsNotExisted: 'Tenant is not existed',
  TenantIdIsRequired: 'TenantId is required',
  UserNotFound: 'User not found',
  UsernamePasswordIsRequired: 'Username and Password is required',
  TicketIsNotExisted: 'Ticket is not existed',
  ServiceIsNotExisted: 'Service is not existed',
  InvalidUserUsername: 'Invalid Username',
  PasswordIsRequired: 'Password is required',
  IncorrectUsernameOrPassword: 'Incorrect Username or Password',
  RefreshTokenIsRequired: 'RefreshToken is required',
  InvalidRefreshToken: 'Invalid refresh token',
  RefreshTokenIsExpired: 'RefreshToken is expired',
  XTenantIdIsNotMatchWithToken: 'XTenantIdIsNotMatchWithToken',
  InvalidUsernameOrPassword: 'Invalid Username or Password',
  InvalidUsername: 'Invalid Username',
  TicketAlreadyExisted: 'Ticket already existed',
  IDsWasWrong: 'ID is wrong',
  IncorrectUserRole: 'Incorrect User Role',
  NotAuthorizedException: 'NotAuthorizedException',
  TrialSubscriptionExpired: 'Trial Subscription Expired',
  UserIsDisabled: 'User Is Disabled',
};

const getUserInfo = () => {
  // let userInfo = JSON.parse(localStorage.getItem('userInfo') || "");
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  if (typeof userInfo === 'string') {
    return JSON.parse(userInfo);
  } else {
    return userInfo;
  }
};

function getAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken || '';
}

function getRefreshToken() {
  const refreshToken = window.localStorage.getItem('refreshToken');
  return refreshToken || '';
}

const AUTH_HOST = AUTH_DOMAIN;

const axiosInstance: any = axios.create({
  baseURL: `${AUTH_HOST}`,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const publicUrl = ['/login', '/register', '/refresh-token'];

// Interceptors
// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config: any) {
    // Do something before request is sent
    if (publicUrl.includes(config?.url)) {
      return config;
    }
    const accessToken = getAccessToken();
    const userInfo = getUserInfo();
    if (!accessToken) {
      // navigate('/login');
    }
    config.headers['X-Tenant-Id'] = `${userInfo.tenantId}`;
    config.headers.authorizer = accessToken;
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  async (err: any) => {
    let isToastError = true;
    const originalConfig = err.config;
    if (err.response.status === 401 || err.response.status === 403) {
      const response = err.response;
      if (response?.data?.messageKey === 'TrialSubscriptionExpired') {
        isToastError = false;
      }

      const refreshToken = getRefreshToken();
      const { messageKey } = err.response.data;
      if (messageKey === 'TokenExpired' || messageKey === 'GeneralUnauthorized' || messageKey === 'TokenIsExpired') {
        originalConfig._retry = true;
        try {
          const res: any = await authService.refreshToken({ refreshToken });

          localStorage.setItem('accessToken', res.data.data);

          return axiosInstance(originalConfig);
        } catch (_error) {
          //clearSession
          //redirect to login
          return Promise.reject(_error);
        }
      }
    }
    const messageKey = err?.response?.data?.messageKey;
    if (isToastError) {
      toast.error((messageNotifyEnglish as any)[`${messageKey}`] || 'Something went wrong.');
    }

    return Promise.reject(err);
  },
);

export default axiosInstance;
