import { FC, useCallback, useState } from 'react';
import authService from 'services/authService';
import { PATH_DASHBOARD, PATH_AUTH } from 'routes/paths';
import { POS_PREVIOUS_DOMAIN } from 'services/appDomain';

// libs

// material core
// import CssBaseline from '@material-ui/core/CssBaseline';

// containers
import ErrorBoundary from 'containers/ErrorBoundary';
import { NavLink, Route, useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Button } from '@mui/material';

// components
// import NavBar from './NavBar';
// import TopBar from './TopBar';

// styles
// import useStyles from './styles';

const MainLayout: FC = ({ children }: any) => {
  const navigate = useNavigate();
  // const classes = useStyles();

  const [isDrawer, setIsDrawer] = useState(true);

  const _handleToogleDrawer = useCallback(() => {
    setIsDrawer(!isDrawer);
  }, [isDrawer]);

  const navLinkStyles: any = (isActive: boolean) => {
    return {
      fontWeight: isActive ? 'bold' : 'normal',
      textDecoration: isActive ? 'none' : 'underline',
    };
  };
  const handleLogout = async () => {
    try {
      // await logout();
      authService.clearSession();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <nav className="primary_nav" style={{ display: 'flex', gap: 20, padding: '2rem', alignItems: 'center' }}>
        <NavLink style={navLinkStyles} to="/">
          HOME
        </NavLink>
        <NavLink style={navLinkStyles} to="/about">
          <span
            onClick={() => {
              const userInfor = localStorage.getItem('userInfo');
              window.open(`${POS_PREVIOUS_DOMAIN}?userInfo=${userInfor}`, 'blank');
            }}
          >
            POS
          </span>
        </NavLink>
        <NavLink style={navLinkStyles} to="/products">
          BOOKING
        </NavLink>
        <Button
          sx={{
            paddingTop: '10px',
            fontWeight: 800,
          }}
          // onClick={() => {
          //   localStorage.removeItem('currentUser')
          //   navigate('login');
          // }}
          onClick={handleLogout}
        >
          Logout
        </Button>
        {/* {!isLogin ?
          <>
            <NavLink style={navLinkStyles} to="/login">
              LOGIN
            </NavLink>
          </>:
          <>
             <Button sx={{
              paddingTop: "10px",
              fontWeight: 800
             }} onClick={()=>{
                localStorage.removeItem('currentUser')
                navigate('login')
              }}>
                Logout
            </Button>
          </>
        } */}
      </nav>
      <main
      // className={clsx(classes.content, {
      //   [classes.contentShift]: isDrawer,
      // })}
      >
        {/* <div className={classes.toolbar} /> */}
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default MainLayout;
